var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeaderDisplay', {
    attrs: {
      "title": "Reports",
      "sub-title": "Generate reports, view history and manage templates.",
      "category": "Reports",
      "category-logo": "fa-file-chart-column",
      "category-route": {
        name: _vm.$route.name
      }
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "automation",
      "tabs": _vm.tabs
    }
  }, [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }