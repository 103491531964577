<template>
  <div>
    <PageHeaderDisplay
      title="Reports"
      sub-title="Generate reports, view history and manage templates."
      category="Reports"
      category-logo="fa-file-chart-column"
      :category-route="{ name: $route.name }"
    />

    <TabView route-name="automation" :tabs="tabs">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import PageHeaderDisplay from '@/components/PageHeaderDisplay';
import TabView from '@/components/TabView';

export default {
  name: 'Report',
  components: {
    PageHeaderDisplay,
    TabView
  },
  data() {
    return {
      tabs: [
        { name: 'Reports', icon: 'fa-rectangle-history', route: 'reports-list', altRoutes: ['reports-generate'] },
        { name: 'Dynamic Report', icon: 'fa-file-chart-column', route: 'reports-dynamic' },
        { name: 'Scheduled Reports', icon: 'fa-calendar-days', route: 'reports-schedule' },
        { name: 'History', icon: 'fa-clock-rotate-left', route: 'reports-history' }
      ]
    };
  }
};
</script>
